.activity-bg {
  width: 100%;
  height: 1100px;
  background-color: #272727;
}

.card-activity {
  height: 350px;
  max-width: 500px;
  background-color: #ffffff;
  text-align: left;
  transition: 0.3s;
  margin: auto;
  color: #000000;
  padding: 0;
  overflow: hidden;
  border: 1px solid #ffffff;
}

.card {
  border-radius: 5px;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 16px 0 rgba(255, 255, 255, 0.5);
}

.card-img-top-activity {
  height: 285px;
  width: auto;
}

.card-body {
  height: 285px;
}

.card-title-activity {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
}

.card-text-activity {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
}

.card-footer-activity {
  height: 65px;
  text-align: center;
}

.activity-title {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  margin-top: 10px;
}

.activity-date {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
}

.activity-detail {
  position: absolute;
  z-index: 10;
  height: 100%;
  width: 490px;
  background-color: #272727f1;
  animation: animatebottom 0.5s;
  color: #ffffff;
  opacity: 1;
  padding: 10px;
}

@keyframes animatebottom {
  from {
    top: 100%;
  }

  to {
    top: 0;
  }
}

div.activity-detail-hide {
  display: none;
}

.card:hover .activity-detail {
  display: block !important;
}

.activity-detail-title {
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  margin-top: 20px;
}

.activity-detail-desc {
  margin-top: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
}

@media only screen and (max-width: 800px) {
  .activity-detail {
    position: absolute;
    z-index: 10;
    height: 100%;
    width: 98%;
    background-color: #272727f1;
    animation: animatebottom 0.5s;
    color: #ffffff;
    opacity: 1;
    padding: 10px;
  }

  .card:hover {
    transform: scale(1);
    box-shadow: none
  }
}

@media only screen and (max-width: 660px) {
  .activity-detail {
    position: absolute;
    z-index: 10;
    height: 100%;
    width: 95%;
    background-color: #272727f1;
    animation: animatebottom 0.5s;
    color: #ffffff;
    opacity: 1;
    padding: 10px;
  }

  .card:hover {
    transform: scale(1);
    box-shadow: none
  }
}