.bg-fixed-pd {
  position: fixed !important;
  background-color: #1E1C8B !important;
  height: 60px !important;
  z-index: 999;
  max-width: none !important;

}

.sticky-pd {
  position: relative !important;
  height: 60px !important;
  font-size: 15px !important;
  top: 50%;
  left: 50%;
  margin-left: -25%;
  /* Negative half of width. */
}

.fake-con-pd {
  width: 100% !important;
  padding-right: 15px !important;
  padding-left: 15px !important;
  margin-right: auto !important;
  margin-left: auto !important;
  max-width: 960px;
}

.image-icon-pd {
  width: 40px;
  height: 40px;
  left: 30px;
  top: 30px;
  border-radius: 10px;
  cursor: pointer;
}

.image-col2-pd {
  position: absolute;
  z-index: 2000;
  top: 20px;
  left: 20px;
  text-align: left;
  height: 100px;
}

.img-fixed-pd {
  top: 10px !important;
  left: 5px !important;
  position: fixed !important;
}

.sticky-pd .menu_link-pd {
  color: rgba(255, 255, 255, 1) !important;
  padding-top: 20px !important;
}

#main_menu-pd {
  position: absolute;
  z-index: 999;
  top: 0;
  width: auto;
  height: 100px;
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  display: flex;
  list-style-type: none;
}

ul.sticky-pd {
  padding-inline-start: 80px !important;
}

.menu_link-pd {
  color: #ffffff;
  display: block;
  cursor: pointer;
  display: list-item;
  text-align: -webkit-match-parent;
  padding: 40px 50px 10px 50px;


}

.sticky-pd .menu_link-pd:hover {
  text-decoration: none;
  color: #c7c7c7 !important;
  transform: none !important;

}

.menu_link-pd:hover {
  text-decoration: none;
  color: #ffffff;
  transform: scale(1.05);

}


.grid_container-pd {
  margin-top: 20px;
}

.productdetail-content {
  color: #000000 !important;
  padding-top: 60px;
}

.menu-pd {
  position: fixed;
  height: 100vh;
  z-index: 10;
  opacity: 0.9;
  border-right: 1px solid #8b8b8b;
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.company-pd {
  margin-top: 20px;
  padding-bottom: 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  border-bottom: 1px solid #8b8b8b;
}

.MuiLink-underlineHover:hover {
  text-decoration: none !important;
}